<template>
  <!-- <div id="User"> -->
  <div class="User-box">
    
    <!-- 修改头像和名称 -->

    <div class="user_container">
      <router-view />
    </div>
   
  </div>
  <!-- </div> -->
</template>

<script>
import { mapMutations } from "vuex";
import { PersonalInfo, Set_avatar, base64, Set_Name } from "@/network/api.js";
import { imgurlvalue } from "./index";
import myUpload from "vue-image-crop-upload";

export default {
  components: {
    "my-upload": myUpload,
  },
  name: "User",
  data() {
    return {
      ZheZhaoShow: false,
      loadingShow: true,
      menuKey: 1,
      size: 2.1,
      mobile: "",
      name: "",
      avatar: "",
      message: {},
      phoneShow: false,
      show: false,
      isupdataimg: "", //更新时的头像显示
      usershow: false, //用户修改头像和修改昵称开关
      imgurlvalue, //模拟头像
      changehead: 0, //选择头像change
      namevalu: "", //用户输入的昵称
      file: null, //用户选择文件
      headerName: "个人资料",
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        this.headerName = "个人资料";
        if (newVal.name == "Warehouse") {
          this.headerName = "我的背包";
        }
        if (newVal.name == "BagRecord") {
          this.headerName = "钱包记录";
        }
        if (newVal.name == "BagRecharge") {
          this.headerName = "钱包充值";
        }
        if (newVal.name == "shareReward") {
          this.headerName = "分享奖励";
        }
        if (newVal.name == "Highlight") {
          this.headerName = "高光时刻";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    restPhone() {
      if (this.mobile.length > 0) {
        return this.mobile.replace(
          this.mobile.substring(3, this.mobile.length),
          "********"
        );
      }
      return "";
    },
  },
  created() {
    this.GetPersonalInfo();
  },
  methods: {
    imgToBase64Cancas(url, callback) {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.naturalWidth; // 使用 naturalWidth 为了保证图片的清晰度
        canvas.height = image.naturalHeight;
        canvas.style.width = `${canvas.width / window.devicePixelRatio}px`;
        canvas.style.height = `${canvas.height / window.devicePixelRatio}px`;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          return null;
        }
        ctx.drawImage(image, 0, 0);
        const base64 = canvas.toDataURL("image/png");
        callback(base64);
      };
      image.onerror = (err) => {
        callback("");
      };
    },
    // 用户点击确认
    updata() {
      // 获取用户输入昵称

      let res = this.namevalu;
      // 昵称输入框正则验证
      let reg = /^[\u4e00-\u9fa5\w]{2,8}$/;
      if (!reg.test(res)) {
        this.$message({
          message:
            "最少输入2个字符，最多输入8个字符，可以包含汉字、字母、下划线",
          type: "error",
        });
      } else {
        Set_Name(res).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.GetPersonalInfo();
            this.usershow = false;
          }
        });
        //头像修改
        Set_avatar(this.isupdataimg).then((res) => {
          PersonalInfo().then((res) => {
            this.User(res.data.data);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.GetPersonalInfo();
            this.usershow = false;
          });
        });
      }
    },
    // 选择头像改变
    imgchange(v, i) {
      this.changehead = i;
      this.isupdataimg = v.url;
      this.imgToBase64Cancas(`${v.url}`, (beas) => {
        base64(beas).then((res) => {
          if (res.data.code == 200) {
            this.isupdataimg = res.data.data.url;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      });
    },
    uploadbease() {
      // 头像点击上传
      this.show = !this.show;

      let filedom = document.getElementById("upload");
      filedom.click();
    },
    handleFileUpload(e) {
      // window.URL.createObjectURL(e.target.files[0])
      var reader = new FileReader();
      reader.onload = (e) => {
        let beas = e.target.result;
        base64(beas).then((res) => {
          if (res.data.code == 200) {
            this.isupdataimg = res.data.data.url;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    },
    //头像上传
    toggleShow() {
      this.usershow = !this.usershow;
      this.namevalu = this.name;
      this.isupdataimg = this.avatar;
    },
    ToRoute(str) {
      this.$router.push(str);
    },
    //获取个人信息
    GetPersonalInfo() {
      // 本地获取信息
      PersonalInfo().then((res) => {
        const { mobile, name, avatar } = res.data.data;
        this.message = res.data.data;
        this.mobile = mobile || "";
        this.name = name || "";
        this.avatar = avatar || "";
        localStorage.setItem("boxUserInfo", JSON.stringify(this.message));
        this.User(this.message);
      });
    },
    //退出
    SignOut() {
      this.GetIndividualsShow(false);
      this.Leave();
      window.location.replace("/Home");
    },

    ...mapMutations(["GetIndividualsShow", "Leave", "User"]),
  },
};
</script>

<style lang="scss">
</style>
